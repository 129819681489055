import React from "react";

function isColorCode(str) {
  if (str) {
    const colorStr = str.replace(/\s/g, "");
    const colorCodeRegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const rgbRegExp = /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/;
    const rgbaRegExp =
      /^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(0(\.\d+)?|1(\.0)?)\)$/;
    if (
      colorCodeRegExp.test(colorStr) ||
      rgbRegExp.test(colorStr) ||
      rgbaRegExp.test(colorStr)
    ) {
      return str;
    }
  }
  return null;
}

function Logo({
  className,
  width,
  color,
  bgColor,
  hiddenSubTitle,
  inPageHeader,
  ...otherProps
}) {
  const defStyle = {
    width: inPageHeader ? "350px" : "150px",
    color: "#000000",
    bgColor: "transparent",
    tunnelColor: "#FFFFFF",
  };
  const mainStyle = {
    width: width || defStyle.width,
    backgroundColor: isColorCode(bgColor) || defStyle.bgColor,
    fill: isColorCode(color) || defStyle.color,
  };
  return (
    <div className={className} style={mainStyle} {...otherProps}>
      <svg
        version="1.1"
        id="katman_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 595.3 254"
        // style="enable-background:new 0 0 595.3 254;"
      >
        <g>
          <path
            d="M426.3,90.2c0,48.2-34.5,84.1-82.5,84.1c-48,0-82.5-35.9-82.5-84.1c0-48,34.5-83.9,82.5-83.9
		C391.8,6.3,426.3,42.2,426.3,90.2 M386.5,90.2c0-22.9-14.4-45.6-42.6-45.6c-28.3,0-42.6,22.7-42.6,45.6c0,22.9,14.4,45.9,42.6,45.9
		C372.1,136.1,386.5,113.1,386.5,90.2"
          />
          <path
            d="M557.4,121l33.1,21.8c-14.6,19.5-37.8,31.5-65.6,31.5c-48,0-82.5-35.9-82.5-84.1c0-48,34.5-83.9,82.5-83.9
		c27.8,0,51,12,65.6,31.3l-33.1,21.8c-7-8.8-17.8-14.8-32.4-14.8c-28.3,0-42.6,22.7-42.6,45.6c0,22.9,14.4,45.9,42.6,45.9
		C539.6,136.1,550.5,130,557.4,121"
          />
          <path
            d="M42.6,231l2.1,1.4c-3,3.8-7.7,6.3-13,6.3c-9.5,0-16.8-7.7-16.8-17.2c0-9.5,7.3-17.2,16.8-17.2
		c5.3,0,10,2.4,13,6.3l-2.1,1.4c-2.6-3.1-6.4-5.1-10.9-5.1c-8.1,0-14.2,6.6-14.2,14.7c0,8.1,6.1,14.7,14.2,14.7
		C36.2,236.2,40,234.2,42.6,231"
          />
          <path
            d="M73.2,226.1c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		C67.7,213.5,73.2,219.1,73.2,226.1 M70.7,226.1c0-5.5-4.4-10.1-10-10.1c-5.5,0-10,4.6-10,10.1c0,5.5,4.4,10.1,10,10.1
		C66.3,236.2,70.7,231.6,70.7,226.1"
          />
          <path
            d="M98.8,223.9v14.2h-2.5v-14.2c0-3.3-1.6-7.9-7.1-7.9c-5.5,0-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8
		c1.6-2.5,4.2-4.4,8-4.4C96.1,213.5,98.8,219.2,98.8,223.9"
          />
          <path
            d="M121,231.5c0,3.6-2.6,7.2-8.4,7.2c-4.6,0-7.7-2.6-8.6-5.5l2.4-0.8c0.6,1.9,2.9,3.7,6.2,3.7
		c3.6,0,5.9-1.8,5.9-4.6c0-2.6-1.8-3.4-5.7-4.2c-3.6-0.7-7.9-2-7.9-6.8c0-3.9,3.2-7,7.5-7c3.7,0,6.5,2,7.5,5.4l-2.5,0.7
		c-0.7-2.2-2.5-3.5-5-3.5c-2.9,0-5,1.9-5,4.5c0,2.7,2.3,3.6,6,4.4C116.8,225.6,121,226.6,121,231.5"
          />
          <path
            d="M136.7,235.7v2.5c-3.9,0.4-8.2,0-8.2-7.3v-14.3h-4.2v-2.6h4.2v-5h2.5v5h5.6v2.6H131v14.3
		C131,234.5,132.1,236.2,136.7,235.7"
          />
          <path d="M154,213.5v2.9c-5.3,0-8.5,3.4-8.5,9.4v12.2h-2.6v-24h2.6v5.2C147.1,216,150.1,213.5,154,213.5" />
          <path
            d="M179.1,214.1v24h-2.6v-3.7c-1.5,2.5-4.1,4.4-7.9,4.4c-7,0-9.7-5.7-9.7-10.4v-14.2h2.6v14.2
		c0,3.3,1.6,7.9,7.1,7.9c5.5,0,7.9-4.6,7.9-7.9v-14.2H179.1z"
          />
          <path
            d="M205.3,231.8l2.3,1.2c-2.2,3.4-6.1,5.7-10.4,5.7c-7,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		c4.4,0,8.2,2.2,10.4,5.6l-2.3,1.2c-1.8-2.6-4.7-4.4-8.2-4.4c-5.5,0-10,4.6-10,10.1s4.4,10.1,10,10.1
		C200.5,236.2,203.5,234.4,205.3,231.8"
          />
          <path
            d="M222.8,235.7v2.5c-3.9,0.4-8.2,0-8.2-7.3v-14.3h-4.2v-2.6h4.2v-5h2.5v5h5.6v2.6h-5.6v14.3
		C217.1,234.5,218.2,236.2,222.8,235.7"
          />
          <path
            d="M228.6,207.3c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
		C229.3,209,228.6,208.3,228.6,207.3 M229,214.1h2.6v24H229V214.1z"
          />
          <path
            d="M261.9,226.1c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		C256.4,213.5,261.9,219.1,261.9,226.1 M259.4,226.1c0-5.5-4.4-10.1-10-10.1c-5.5,0-10,4.6-10,10.1c0,5.5,4.4,10.1,10,10.1
		C255,236.2,259.4,231.6,259.4,226.1"
          />
          <path
            d="M287.5,223.9v14.2H285v-14.2c0-3.3-1.6-7.9-7.1-7.9s-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8c1.6-2.5,4.2-4.4,8-4.4
		C284.8,213.5,287.5,219.2,287.5,223.9"
          />
          <polygon
            points="327.4,207.5 318.3,207.5 318.3,238.1 315.8,238.1 315.8,207.5 306.7,207.5 306.7,204.9 327.4,204.9 	
		"
          />
          <path
            d="M351.6,227.4h-20.7c0.6,4.9,4.6,8.8,9.7,8.8c3.2,0,5.8-1.2,7.7-3.5l2.2,1.4c-2.4,3-5.8,4.6-9.9,4.6
		c-6.9,0-12.3-5.7-12.3-12.6c0-6.8,5-12.6,11.9-12.6C346.9,213.5,352,219,351.6,227.4 M330.9,224.8H349c-0.6-5.3-4.2-8.8-8.8-8.8
		C335.2,216,331.5,219.9,330.9,224.8"
          />
          <path
            d="M376.1,231.8l2.3,1.2c-2.2,3.4-6.1,5.7-10.4,5.7c-7,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		c4.4,0,8.2,2.2,10.4,5.6l-2.3,1.2c-1.8-2.6-4.7-4.4-8.2-4.4c-5.5,0-10,4.6-10,10.1s4.4,10.1,10,10.1
		C371.4,236.2,374.4,234.4,376.1,231.8"
          />
          <path
            d="M403.5,223.9v14.2H401v-14.2c0-3.3-1.6-7.9-7.1-7.9c-5.5,0-8,4.6-8,7.9v14.2h-2.5v-33.2h2.5v12.9
		c1.6-2.5,4.2-4.4,8-4.4C400.8,213.5,403.5,219.2,403.5,223.9"
          />
          <path
            d="M430.8,223.9v14.2h-2.5v-14.2c0-3.3-1.6-7.9-7.1-7.9c-5.5,0-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8
		c1.6-2.5,4.2-4.4,8-4.4C428.1,213.5,430.8,219.2,430.8,223.9"
          />
          <path
            d="M461.2,226.1c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		C455.7,213.5,461.2,219.1,461.2,226.1 M458.6,226.1c0-5.5-4.4-10.1-10-10.1c-5.5,0-10,4.6-10,10.1c0,5.5,4.4,10.1,10,10.1
		C454.2,236.2,458.6,231.6,458.6,226.1"
          />
          <rect x="466.6" y="204.9" width="2.5" height="33.2" />
          <path
            d="M499.5,226.1c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
		C494,213.5,499.5,219.1,499.5,226.1 M497,226.1c0-5.5-4.4-10.1-10-10.1c-5.5,0-10,4.6-10,10.1c0,5.5,4.4,10.1,10,10.1
		C492.6,236.2,497,231.6,497,226.1"
          />
          <path
            d="M527.3,214.1v21.5c0,6.1-4.1,12.1-12.2,12.1c-4.2,0-8-1.7-10.3-4.6l2.1-1.4c1.9,2.4,4.7,3.6,8.2,3.6
		c6.7,0,9.6-4.8,9.6-9.6v-3.5c-1.8,3.2-5.2,5.4-9.4,5.4c-6.7,0-12-5.3-12-12c0-6.7,5.3-12,12-12c4.2,0,7.6,2.1,9.4,5.4v-4.8H527.3z
		 M524.7,225.4c0-5.3-4.1-9.4-9.4-9.4c-5.4,0-9.5,4.1-9.5,9.4c0,5.4,4.1,9.4,9.5,9.4C520.6,234.9,524.7,230.8,524.7,225.4"
          />
          <path
            d="M534,207.3c0-0.9,0.7-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7C534.8,209,534,208.3,534,207.3
		 M534.4,214.1h2.6v24h-2.6V214.1z"
          />
          <path
            d="M565.7,227.4H545c0.6,4.9,4.6,8.8,9.7,8.8c3.2,0,5.8-1.2,7.7-3.5l2.2,1.4c-2.4,3-5.8,4.6-9.9,4.6
		c-6.9,0-12.3-5.7-12.3-12.6c0-6.8,5-12.6,11.9-12.6C561,213.5,566.1,219,565.7,227.4 M545,224.8h18.1c-0.6-5.3-4.2-8.8-8.8-8.8
		C549.3,216,545.6,219.9,545,224.8"
          />
          <path
            d="M586.3,231.5c0,3.6-2.6,7.2-8.4,7.2c-4.6,0-7.7-2.6-8.6-5.5l2.4-0.8c0.6,1.9,2.9,3.7,6.2,3.7
		c3.6,0,5.9-1.8,5.9-4.6c0-2.6-1.8-3.4-5.7-4.2c-3.6-0.7-7.9-2-7.9-6.8c0-3.9,3.2-7,7.5-7c3.7,0,6.5,2,7.5,5.4l-2.5,0.7
		c-0.7-2.2-2.5-3.5-5-3.5c-2.9,0-5,1.9-5,4.5c0,2.7,2.3,3.6,6,4.4C582.1,225.6,586.3,226.6,586.3,231.5"
          />
          <path
            d="M35.8,154.3h24.6c8,0,13.3-0.1,13.3-8.9c0-1.7-0.1-2.9-0.2-4.1h8.9c0.3,1.4,0.6,3.2,0.6,5.4
		c0,12.2-6.5,17.6-18.9,17.6H35.8v8.8h-9.7v-8.8H14.4v-10.1h11.8v-13h9.7V154.3z"
          />
          <path
            d="M47.2,79.4h35.2v10.2H50.1c-7.9,0-15.8,3.3-15.8,13.9c0,10.7,9.2,15,15.8,15h32.3v10.1H4.7v-10.1H34
		c-5-3.3-9.2-8.9-9.2-17.4C24.8,88.3,35.3,79.4,47.2,79.4"
          />
          <path
            d="M58.6,12.8v44.7c9.2-1.6,15.5-8.4,15.5-19.4c0-6.1-2.3-11.7-6.3-14.8l5.3-8.3c6.8,5,10.7,13.2,10.7,23.1
		c0,18.6-13.3,30.2-29.5,30.2c-16.2,0-29.5-12-29.5-28.5C24.8,24.4,37.7,10.1,58.6,12.8 M50,23c-9.5,1.3-15.6,7.2-15.6,16.8
		c0,9.7,6.3,16.2,15.6,17.8V23z"
          />
          <path
            d="M220.8,105.2c15.5-8.8,25-23.5,24.9-42.9c-0.1-32.4-21.2-56-62.4-55.9L121,6.5l0.4,160.8v1.2h24.3l29.5-135.5
		l29.5,135.5h9.7l41.2-0.1L220.8,105.2z"
          />
          <path
            d="M175.1,168.4c-1.4,0-2.5-1.1-2.5-2.5V131c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v34.9
		C177.6,167.3,176.5,168.4,175.1,168.4"
          />
          <path
            d="M175.1,110.3c-1.4,0-2.5-1.1-2.5-2.5V83c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v24.8
		C177.6,109.2,176.5,110.3,175.1,110.3"
          />
        </g>
      </svg>
    </div>
  );
}

export default Logo;
