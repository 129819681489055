
export const blogData = [
    {
        id: 1,
        category: ["Web design", "Concept"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 1,
        likes: 28,
        comments: 0,
        date: "18 February 2020"
    },
    {
        id: 2,
        category: ["CONCEPT", "Web design"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 2,
        likes: 30,
        comments: 0,
        date: "09 January 2020"
    },
    {
        id: 3,
        category: ["NATURE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "People ignore designs that ignore people",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 23,
        comments: 0,
        date: "12 December 2020"
    },
    {
        id: 4,
        category: ["MODERN"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Look at usual things with unusual eyes",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 4,
        likes: 35,
        comments: 0,
        date: "23 November 2020"
    },
    {
        id: 5,
        category: ["FASHION"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Fashion is not something exists in dresses",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 5,
        likes: 25,
        comments: 0,
        date: "12 January 2020"
    },
    {
        id: 6,
        category: ["Lifestyle"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 6,
        likes: 35,
        comments: 0,
        date: "05 November 2020"
    },
    {
        id: 7,
        category: ["FURNITURE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Houseful of new furniture doesn't mean lot",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 7,
        likes: 20,
        comments: 0,
        date: "19 October 2020"
    },
    {
        id: 8,
        category: ["BRANDING"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "The best comfort food will always be greens",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 8,
        likes: 28,
        comments: 0,
        date: "09 September 2020"
    },
    {
        id: 9,
        category: ["TRAVELLING"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "I love traveling all over the world but it's true",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 9,
        likes: 25,
        comments: 0,
        date: "18 August 2020"
    },
    {
        id: 10,
        category: ["CREATIVE"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "A true photograph not be explained, words",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 28,
        comments: 0,
        date: "26 June 2020"
    },
    {
        id: 11,
        category: ["BUSINESS"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Consider every mistake do make as asset",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 9,
        likes: 35,
        comments: 0,
        date: "20 April 2020"
    },
    {
        id: 12,
        category: ["EVENTS"],
        blogType: "grid",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 5,
        likes: 22,
        comments: 0,
        date: "14 March 2020"
    },
    {
        id: 13,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 14,
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FASHION"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 2,
        likes: 25,
        comments: 0,
        date: "17 June 2020",
    },
    {
        id: 15,
        img: "https://via.placeholder.com/800x560",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CREATIVE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 16,
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["MODERN", "BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 17,
        img: "https://via.placeholder.com/800x560",
        title: "Reason and judgment are the qualities of leader",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CORPORATE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 5,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 18,
        img: "https://via.placeholder.com/800x560",
        title: "Business is combination of war and sport",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FINANCE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 6,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 19,
        img: "https://via.placeholder.com/800x560",
        title: "Look at usual things with unusual for eyes",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["BUSINESS"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 7,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 20,
        img: "https://via.placeholder.com/800x560",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CONCEPT"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 8,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 21,
        img: "https://via.placeholder.com/800x560",
        title: "Good work for people good design is a language",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["FURNITURE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 9,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 22,
        img: "https://via.placeholder.com/800x560",
        title: "Creativity is only obscure your reference",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 23,
        img: "https://via.placeholder.com/800x560",
        title: "All progress takes place for outside comfort zone",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["DIGITAL"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 25,
        img: "https://via.placeholder.com/800x560",
        title: "Everything is designed for things are designed",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["CREATIVE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 26,
        img: "https://via.placeholder.com/800x560",
        title: "Fashion and interior design are one the",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["Web Design"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 27,
        img: "https://via.placeholder.com/800x560",
        title: "The best comfort food will always be greens",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["SUMMER"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 5,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 28,
        img: "https://via.placeholder.com/800x560",
        title: "Get in over your head as often an possible",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 6,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 29,
        category: ["CREATIVE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 9,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 30,
        category: ["CONCEPT"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Simplicity, wit, and good typography",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 31,
        category: ["BUSINESS"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Look at usual things with unusual",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 4,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 32,
        category: ["LIFESTYLE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Do not seek praise seek criticism",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 33,
        category: ["MODERN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Make it simple, but significant",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 2,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 34,
        category: ["FURNITURE"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Leave it better than you found it",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 35,
        category: ["DESIGN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Everything is design are designed well",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 36,
        category: ["FASHION"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Fashion fades, only style remains same",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 37,
        category: ["MODERN"],
        blogType: "simple",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x970",
        title: "Never give in except convictions sense",
        content: "Lorem ipsum is simply dummy text printing...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 38,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 39,
        img: "https://via.placeholder.com/800x850",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 2,
        category: ["LIFESTYLE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 40,
        img: "https://via.placeholder.com/800x580",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 41,
        img: "https://via.placeholder.com/800x850",
        title: "Opportunities don't happen. You create them",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 4,
        category: ["TRAVEL"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 18,
        comments: 62,
        date: "15 December 2019",
    },
    {
        id: 42,
        img: "https://via.placeholder.com/800x850",
        title: "Never give in except to convictions good sense",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 5,
        category: ["MODERN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 22,
        comments: 38,
        date: "12 November 2019",
    },
    {
        id: 43,
        img: "https://via.placeholder.com/800x850",
        title: "An economist's guess is liable good as anybody",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 6,
        category: ["FOOD"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 12,
        comments: 52,
        date: "18 January 2020",
    },
    {
        id: 44,
        img: "https://via.placeholder.com/800x850",
        title: "All progress takes place outside the comfort zone",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 7,
        category: ["EVENTS"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "22 November 2019",
    },
    {
        id: 45,
        img: "https://via.placeholder.com/800x850",
        title: "Reason and judgment are the qualities of a leader",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 8,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 25,
        comments: 45,
        date: "16 October 2019",
    },
    {
        id: 46,
        img: "https://via.placeholder.com/800x850",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 9,
        category: ["FURNITURE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 50,
        date: "20 September 2019",
    },
    {
        id: 47,
        img: "https://via.placeholder.com/800x850",
        title: "People ignore designs that ignore people",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 32,
        comments: 42,
        date: "26 August 2019",
    },
    {
        id: 48,
        img: "https://via.placeholder.com/800x580",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 2,
        category: ["CONCEPT"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 36,
        comments: 52,
        date: "20 September 2019",
    },
    {
        id: 49,
        img: "https://via.placeholder.com/800x580",
        title: "A true photograph not be explained, words",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["MODERN"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "20 June 2019",
    },
    {
        id: 50,
        category: ["CREATIVE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Recognizing the need the primary condition design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "18 MARCH 2020",

    },
    {
        id: 51,
        category: ["BUSINESS"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Good design is obvious great design transparent",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "25 FEBRUARY 2020",

    },
    {
        id: 52,
        category: ["MODERN"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Any product that needs manual to work is broken",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "30 JANUARY 2020",

    },
    {
        id: 53,
        category: ["LIFESTYLE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Everything is designed for things are the designed",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "23 DECEMBER 2019",

    },
    {
        id: 54,
        category: ["FURNITURE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Houseful of new furniture doesn't mean lot modern",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "20 OCTOBER 2019",

    },
    {
        id: 55,
        category: ["FINANCE"],
        blogType: "sideImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/1200x840",
        title: "Consider every mistake for you do make as an asset",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 7,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",

    },
    {
        id: 56,
        category: ["ARCHITECTURE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/940x940",
        title: "Architects design houses. I live in a home",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 MARCH 2020",
        double_col: true
    },
    {
        id: 57,
        category: ["CREATIVITY"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Good design doesn't date bad design does",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 FEBRUARY 2020",
        double_col: false
    },
    {
        id: 58,
        category: ["MODERN"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Bad design is smoke, while good design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "20 JANUARY 2020",
        double_col: false
    },
    {
        id: 59,
        category: ["BUSINESS"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/940x940",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 DECEMBER 2019",
        double_col: true
    },
    {
        id: 60,
        category: ["LIFESTYLE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",
        double_col: false
    },
    {
        id: 61,
        category: ["FASHION"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Consider every mistake you do make as asset",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "12 OCTOBER 2019",
        double_col: false
    },
    {
        id: 62,
        category: ["CONCEPT"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 SEPTEMBER 2019",
        double_col: false
    },
    {
        id: 63,
        category: ["DESIGN"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 AUGUST 2019",
        double_col: false
    },
    {
        id: 64,
        category: ["LIFESTYLE"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUNE 2019",
        double_col: false
    },
    {
        id: 65,
        category: ["CREATIVITY"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Reason and judgment are the qualities of a leader",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 APRIL 2019",
        double_col: false
    },
    {
        id: 66,
        category: ["fashion"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Build perfect websites",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 FEBRUARY 2020",

    },
    {
        id: 67,
        category: ["travel"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Beautiful layouts design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",

    },
    {
        id: 68,
        category: ["fashion", "travel"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Online website builder",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 JANUARY 2020",

    },
    {
        id: 69,
        category: ["travel", "Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Different layout type",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 DECEMBER 2019",

    },
    {
        id: 70,
        category: ["fashion"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Elegant elements design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",

    },
    {
        id: 71,
        category: ["creative"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "The best comfort music",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 OCTOBER 2019",

    },
    {
        id: 72,
        category: ["fashion", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Consider every mistake",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 SEPTEMBER 2019",

    },
    {
        id: 73,
        category: ["travel", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Creativity is only obscure",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 AUGUST 2019",

    },
    {
        id: 74,
        category: ["Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "The best way to predict",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "27 APRIL 2019",

    },
    {
        id: 75,
        category: ["Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Never give in except",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 MARCH 2019",

    },
    {
        id: 76,
        category: ["Lifestyle"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "An economist's guess",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "09 FEBRUARY 2019",

    },
    {
        id: 77,
        category: ["Lifestyle", "Business"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/850x885",
        title: "Look at usual things",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 JANUARY 2019",

    },
    {
        id: 78,
        category: ["CREATIVE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        title: "Tools i use to learn, work and travel...",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        comments: 0,
        date: "11 February 2019"
    },
    {
        id: 79,
        category: ["CONCEPT"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Without good architecture design it’s easy...",
        author: 3,
        comments: 0,
        date: "15 February 2019"
    },
    {
        id: 80,
        category: ["EVENTS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Everybody can be great because...",
        author: 3,
        comments: 0,
        date: "25 February 2019"
    },
    {
        id: 81,
        category: ["LIFESTYLE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Styles come and go design is simple...",
        author: 3,
        comments: 0,
        date: "23 January 2019"
    },
    {
        id: 82,
        category: ["NATURE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Alone we can do so little together...",
        author: 3,
        comments: 0,
        date: "19 December 2019"
    },
    {
        id: 83,
        category: ["MOUNTAINS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Design is inherently best optimistic power...",
        author: 3,
        comments: 0,
        date: "18 January 2019"
    },
    {
        id: 84,
        category: ["FINANCE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "People ignore design that ignores...",
        author: 3,
        comments: 0,
        date: "11 April 2019"
    },
    {
        id: 85,
        category: ["BUSINESS"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "A true photograph not be explained...",
        author: 3,
        comments: 0,
        date: "25 March 2019"
    },
    {
        id: 86,
        category: ["LIFESTYLE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Everything creative design things...",
        author: 3,
        comments: 0,
        date: "19 June 2019"
    },
    {
        id: 87,
        category: ["FASHION"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Consider every mistake do make...",
        author: 3,
        comments: 0,
        date: "30 August 2019"
    },
    {
        id: 88,
        category: ["FURNITURE"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "Simplicity is the ultimate sophistic...",
        author: 3,
        comments: 0,
        date: "12 June 2019"
    },
    {
        id: 89,
        category: ["MODERN"],
        blogType: "widget",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/480x300",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        title: "The best comfort food will always...",
        author: 3,
        comments: 0,
        date: "18 January 2019"
    },
    {
        id: 90,
        category: ["CREATIVE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "DESIGN CAN SPEAK THE IN TONGUE OF ART FORCE OF COMMERCE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 MAY 2020",

    },
    {
        id: 91,
        category: ["CONCEPT"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "SIMPLICITY IS PROCESS THE OBVIOUS AND ADDING MEANINGFUL",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUN 2020",

    },
    {
        id: 92,
        category: ["MODERN"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "EVERYTHING KNOW ABOUT MIDI SKIRTS AND HOW TO WEAR FASHION",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 AUG 2020",

    },
    {
        id: 93,
        category: ["FURNITURE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "RECOGNIZING THE NEED IS THE PRIMARY CONDITION FOR DESIGN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 APR 2020",

    },
    {
        id: 94,
        category: ["FASHION"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "FASHION IS SOMETHING BARBAROUS ON FOR IT INNOVATION",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 MAR 2020",

    },
    {
        id: 95,
        category: ["LIFESTYLE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "RAPPERS WEAR DIAMONDS TO COMPENSATE FOR THE FASHION SENSE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEB 2020",

    },
    {
        id: 96,
        category: ["BUSINESS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "THE DETAILS ARE NOT THE DETAILS. THEY MAKE THE BEST DESIGN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 JAN 2020",

    },
    {
        id: 97,
        category: ["FINANCE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Creativity is only as obscure as the your  reference",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "26 DEC 2019",

    },
    {
        id: 98,
        category: ["MOUNTAINS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "ANY PRODUCT THAT NEEDS A MANUAL TO HARD WORK IS BROKEN",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "09 NOV 2019",

    },
    {
        id: 99,
        category: ["NATURE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "REASON AND JUDGMENT ARE THE QUALITIES OF A GOOD LEADER",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 OCT 2019",

    },
    {
        id: 100,
        category: ["CREATIVE"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "GOOD DESIGN IS OBVIOUS. GREAT DESIGN IS MORE TRANSPARENT",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "16 OCT 2019",

    },
    {
        id: 101,
        category: ["EVENTS"],
        blogType: "overlayImage",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "ALL PROGRESS TAKES PLACE OUTSIDE OF THE COMFORT ZONE",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "25 SEP 2019",

    },
    {
        id: 102,
        category: ["LIFESTYLE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "How to build branded photography studios",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 MAY 2020",

    },
    {
        id: 103,
        category: ["FASHION"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Many things difficult to design prove easy",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 APRIL 2020",

    },
    {
        id: 104,
        category: ["CREATIVE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "The proper study of the science of design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 MARCH 2020",

    },
    {
        id: 105,
        category: ["MODERN"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Creativity is nothing but a mind set free",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "15 FEBRUARY 2020",

    },
    {
        id: 106,
        category: ["CORPORATE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Leave it better than you more found it",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 JANUARY 2020",

    },
    {
        id: 107,
        category: ["FINANCE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Do one thing a every day that scares you",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "24 DECEMBER 2019",

    },
    {
        id: 108,
        category: ["BUSINESS"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "A company is only as good as the people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "16 NOVEMBER 2019",

    },
    {
        id: 109,
        category: ["CONCEPT"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Do not seek praise. Seek the criticism",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 OCTOBER 2019",

    },
    {
        id: 110,
        category: ["FURNITURE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "If you do it right, it will the last forever",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "08 SEPTEMBER 2019",

    },
    {
        id: 111,
        category: ["LIFESTYLE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Judgment are the an qualities of leader",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 APRIL 2019",

    },
    {
        id: 112,
        category: ["DIGITAL"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Simplicity, cognition, and good typography",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "29 AUGUST 2019",

    },
    {
        id: 113,
        category: ["CREATIVE"],
        blogType: "modern",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1010",
        title: "Everything should be made as simple",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 OCT 2019",

    },
    {
        id: 114,
        category: ["Web Design", "drink"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x563",
        title: "Make business easy with beautiful application download store",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 115,
        category: ["Web Design"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x563",
        title: "Change your life today. Don't gamble on the future, act now, without delay.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 116,
        category: ["Web Design", "summer"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x563",
        title: "The time to be happy is now, and the place to be happy is here.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 117,
        category: ["Web Design", "coffee"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x563",
        title: "Whenever I think of the past, it brings back so many memories.",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 25,
        comments: 23,
        date: "05 September 2018",
    },
    {
        id: 118,
        category: ["Architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Architects design houses. I live in a home",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "05 March 2020",
    },
    {
        id: 119,
        category: ["Creativity"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Good design doesn't date bad design does",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "28 February 2020",
    },
    {
        id: 120,
        category: ["Garnett"],
        title: "Fashion and interior design are same",
        img: "https://via.placeholder.com/250x250",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 3,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 121,
        category: ["Garnett"],
        title: "Everything designed things are designed",
        img: "/assets/img/webp/spa-image-05.webp",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 122,
        category: ["Magistre"],
        title: "Consider every mistake do make",
        img: "https://via.placeholder.com/250x250",
        blogType: "lifestyle",
        tags: ["Development", "Events", "Media", "Mountains"],
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
        author: 2,
        likes: 0,
        comments: 0,
        date: "",
    },
    {
        id: 123,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 124,
        img: "https://via.placeholder.com/800x580",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE", "BUSINESS"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 125,
        img: "https://via.placeholder.com/720x522",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["FASHION", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 52,
        date: "24 February 2020",
    },
    {
        id: 126,
        img: "https://via.placeholder.com/720x522",
        title: "Computers are to design as microwaves are to cooking ",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["LIFESTYLE", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 28,
        comments: 40,
        date: "10 February 2020",
    },
    {
        id: 126,
        img: "https://via.placeholder.com/720x522",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 1,
        category: ["DESIGN", "startup"],
        blogType: "masonry",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 127,
        img: "https://via.placeholder.com/526x368",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 128,
        img: "https://via.placeholder.com/526x368",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 129,
        img: "https://via.placeholder.com/526x368",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 130,
        img: "https://via.placeholder.com/526x368",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        likes: 30,
        comments: 42,
        date: "18 January 2020",
    },
    {
        id: 131,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "Incorporating newest trends in digital marketing",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 FEBRUARY 2020",
    },
    {
        id: 132,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "Corporate workplace ethics that makes a difference",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "20 FEBRUARY 2020",
    },
    {
        id: 133,
        category: ["fashion", "consulting"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/361x289",
        title: "We take pride in delivering Intelligent and engaging",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",
    },
    {
        id: 134,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 134,
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 135,
        img: "https://via.placeholder.com/800x560",
        title: "Winners make a habit of facturing positive",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 136,
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 137,
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        category: ["LIFESTYLE", "conference"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        author: 1,
        likes: 25,
        comments: 0,
        date: "13 May 2020",
    },
    {
        id: 138,
        category: ["Creativity", "architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Bad design is smoke, while good design is a mirror",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 139,
        category: ["Design", "architecture", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed well",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 140,
        category: ["Architecture", "architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 141,
        category: ["Interior", "architecture", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Reason and judgment are the qualities of a leader",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 142,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "The best way to predict the future is to create it",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 143,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Tour packages are the easiest and most efficient",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 143,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 144,
        category: ["Interior", "travelagency", "brandingagency"],
        blogType: "classic",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x560",
        title: "A company is only as good as the people it keeps",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 145,
        img: "https://via.placeholder.com/800x560",
        title: "Simple wall decor inspiration saturdays style",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["FURNITURE", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "24 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 146,
        img: "https://via.placeholder.com/800x560",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DECOR", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "10 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 147,
        img: "https://via.placeholder.com/800x560",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN", "decor"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "18 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 148,
        img: "https://via.placeholder.com/800x580",
        title: "Recognizing the need is the primary condition design",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["FASHION", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 52,
        date: "24 February 2020",
        link: "/blogs/blog-post-layout-01",
    },
    {
        id: 149,
        img: "https://via.placeholder.com/800x580",
        title: "Computers are to design as microwaves are to cooking",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["LIFESTYLE", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 40,
        date: "10 February 2020",
        link: "/blogs/blog-post-layout-02",
    },
    {
        id: 150,
        img: "https://via.placeholder.com/800x580",
        title: "Fashion and interior design are one and the same",
        content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
        author: 3,
        category: ["DESIGN", "desinagency"],
        blogType: "masonry",
        likes: 28,
        comments: 40,
        date: "18 January 2020",
        link: "/blogs/blog-post-layout-03",
    },
    {
        id: 151,
        category: ["Creativity", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Architects design houses. I live in a home",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 152,
        category: ["Modern", "creativeagency"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Good design doesn't date. Bad design does",
        content: "",
        author: 1,
        likes: 0,
        comments: 0,
        date: "30 AUGUST 2018"
    },
    {
        id: 153,
        category: ["CONCEPT", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "People ignore design that ignores people",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 SEPTEMBER 2019",
        double_col: false
    },
    {
        id: 154,
        category: ["DESIGN", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Everything designed things are designed",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 AUGUST 2019",
        double_col: false
    },
    {
        id: 155,
        category: ["LIFESTYLE", "photography"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x800",
        title: "Simplicity is the ultimate sophistication",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 JUNE 2019",
        double_col: false
    },
    {
        id: 156,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Build perfect websites",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "23 FEBRUARY 2020",
    },
    {
        id: 157,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Beautiful layouts design",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "18 FEBRUARY 2020",
    },
    {
        id: 158,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Online website builder",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "10 JANUARY 2020",
    },
    {
        id: 159,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Different layout type",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "30 DECEMBER 2019",
    },
    {
        id: 160,
        category: ["fashion", "classicblog"],
        blogType: "clean",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x710",
        title: "Elegant elements design",
        content: "",
        author: 3,
        likes: 25,
        comments: 0,
        date: "22 NOVEMBER 2019",
    },
    {
        id: 161,
        category: ["Architecture"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Architects design houses. I live in a home",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "05 March 2020",
        double_col: false
    },
    {
        id: 162,
        category: ["Creativity"],
        blogType: "metro",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://via.placeholder.com/800x1190",
        title: "Good design doesn't date bad design does",
        content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
        author: 3,
        likes: 25,
        comments: 0,
        date: "28 February 2020",
        double_col: false
    },
]


export const BlogCategoryData = [
    {
        id: 118,
        category: "LIFESTYLE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 119,
        category: "SUMMER",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 120,
        category: "COFFEE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 121,
        category: "FASHION",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 122,
        category: "DRINKS",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    }
]

export const authorData = [
    {
        id: 1,
        img: "https://via.placeholder.com/125x125",
        name: "Torrie asai",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 2,
        img: "https://via.placeholder.com/125x125",
        name: "Walton smith",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 3,
        img: "https://via.placeholder.com/125x125",
        name: "Bill gardner",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 4,
        img: "https://via.placeholder.com/125x125",
        name: "Vico magistre",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 5,
        img: "https://via.placeholder.com/125x125",
        name: "Coco chanel",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 6,
        img: "https://via.placeholder.com/125x125",
        name: "Mark lamb",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 7,
        img: "https://via.placeholder.com/125x125",
        name: "Lindsey bucki",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 8,
        img: "https://via.placeholder.com/125x125",
        name: "Maya angelou",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 9,
        img: "https://via.placeholder.com/125x125",
        name: "Jeremy dupont",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
]

export const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "FASHION",
        key: "fashion "
    },
    {
        title: "TRAVEL",
        key: "travel "
    },
    {
        title: "LIFESTYLE",
        key: "lifestyle "
    },
    {
        title: "BUSINESS",
        key: "business"
    }
]