import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ServerClient, { domain } from "../API/ServerClient";

const DefaultMeta = {
  title: "The Roc",
  description: "Description",
  keywords: "keywords",
  canonical: domain,
};

function PageMetaData({ pageData }) {
  const [applicationModel, setApplicationModel] = useState({});
  const [title, setTitle] = useState("");
  const [langName, setLangName] = useState(ServerClient.actions.getActiveLang());
  const location = useLocation();

  useEffect(() => {
    ServerClient.actions.getAppModel((appModel) => {
      setApplicationModel(appModel);
    });
  }, []);

  useEffect(() => {
    setPageTitle();
  }, [pageData, applicationModel]);

  const setPageTitle = () => {
    let docTitle = document.title;
    let appTitle = applicationModel?.siteInfo?.title;
    if (applicationModel?.siteInfo?.titleOtherLangs?.[langName]) {
      appTitle = applicationModel.siteInfo.titleOtherLangs[langName];
    }
    let pageTitle = appTitle || docTitle;
    if (pageData) {
      if (pageData?.title) {
        pageTitle = `${pageData.title} - ${appTitle}`;
      }
    } else {
      pageTitle = `Sayfa Bulunamadı - ${appTitle}`;
    }
    setTitle(pageTitle);
  };

  return (
    <Helmet>
      <title>{title || DefaultMeta.title}</title>
      <meta name="description" content={pageData.description || DefaultMeta.description} />
      <meta name="keywords" content={pageData.keywords || DefaultMeta.keywords} />
      <link rel="canonical" href={`${domain}${location.pathname}` || DefaultMeta.canonical} />
    </Helmet>
  );
}

export default PageMetaData;
