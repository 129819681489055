import React, { memo, useContext, useEffect, useState } from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header, { HeaderLanguage, HeaderNav, Menu, SearchBar, Topbar } from "../Components/Header/Header";
import ServerClient from "../API/ServerClient";
import { US, TR } from "country-flag-icons/react/3x2";

// Context
import GlobalContext from "../Context/Context";
import Logo from "../Components/Logo";

const HeaderLayout = memo((props) => {
  const [applicationModel, setApplicationModel] = useState({});
  const [langName, setLangName] = useState(ServerClient.actions.getActiveLang());
  const { logoInfo } = useContext(GlobalContext);

  useEffect(() => {
    ServerClient.actions.getAppModel((appModel) => {
      setApplicationModel(appModel);
    });
  }, []);

  if (Object.keys(applicationModel).length === 0) {
    return null;
  }

  let systemMenu = applicationModel.menu.sort(function (x, y) {
    if (x.order < y.order) {
      return -1;
    }
    if (x.order > y.order) {
      return 1;
    }
    return 0;
  });

  const getActiveLangValue = (item) => {
    let newMenuItem = {};
    for (const [key, value] of Object.entries(item)) {
      if (key === "megamenu" || key === "dropdown") {
        let valueArray = value.map((v) => {
          return getActiveLangValue(v);
        });
        newMenuItem[key] = valueArray;
      } else {
        let newValue = value;
        if (item?.[key + "OtherLangs"]?.[langName]) {
          newValue = item[key + "OtherLangs"][langName];
        }
        newMenuItem[key] = newValue;
      }
    }
    return newMenuItem;
  };
  systemMenu = systemMenu.map((menuItem) => getActiveLangValue(menuItem));

  return (
    <>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll border-b border-[#ffffff1a]">
        <Topbar className="header-with-topbar shadow-md bg-dark text-[#bf8c4c] pl-[35px] pr-[50px] sm:hidden lg:pr-[50px] md:pl-[15px] md:pr-[30px]">
          <Container fluid>
            <Row className="flex items-center justify-between">
              <Col sm="auto">
                <p className="text-md">{applicationModel.siteInfo.subTitle}</p>
              </Col>
              <Col sm="auto" className="!pr-0">
                <div className="flex text-sm leading-[30px]">
                  <span className="flex items-center py-[9px] px-[18px]">
                    <i className="feather-phone-call mr-[10px] text-md text-white"></i>
                    {applicationModel.siteInfo.phone}
                  </span>
                  <span className="flex items-center py-[9px] pl-[18px]">
                    <i className="feather-map-pin mr-[10px] text-md text-white"></i>
                    {applicationModel.siteInfo.adress}
                  </span>
                  {/* {ServerClient.actions.getActiveLang() == "tr" ? (
                    <span
                      className="flex items-center py-[9px] pl-[18px] cursor-pointer"
                      onClick={() => {
                        ServerClient.actions.setActiveLang("en");
                        window.location.reload();
                      }}
                    >
                      <i className="mr-[10px] text-md text-white">
                        <US title="United States" className="min-w-[14px]" />
                      </i>
                      English
                    </span>
                  ) : (
                    <span
                      className="flex items-center py-[9px] pl-[18px] cursor-pointer"
                      onClick={() => {
                        ServerClient.actions.setActiveLang("tr");
                        window.location.reload();
                      }}
                    >
                      <i className="mr-[10px] text-md text-white">
                        <TR title="Türkiye" className="min-w-[14px]" />
                      </i>
                      Türkçe
                    </span>
                  )} */}
                </div>
              </Col>
            </Row>
          </Container>
        </Topbar>
        <HeaderNav fluid="fluid" theme="light" expand="lg" className="py-[0px] px-[35px] lg:pr-[35px] md:px-0 md:py-[20px] " bg={'white'}>
          <Col className="col-6 col-lg-2 me-auto ps-lg-0 ps-0 ps-md-3">
            <Link aria-label="header home link" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <Logo className="default-logo" color={logoInfo.color || "#000000"} bgColor={logoInfo.bgColor || "transparent"} />
                <Logo className="alt-logo" />
                <Logo className="mobile-logo" />
                {/* <img className="default-logo" width="111" height="36" loading="lazy" src={applicationModel.siteLogo.default} alt="logo" />
                <img className="alt-logo" width="111" height="36" loading="lazy" src={applicationModel.siteLogo.alt} alt="logo" />
                <img className="mobile-logo" width="111" height="36" loading="lazy" src={applicationModel.siteLogo.mobile} alt="logo" /> */}
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:mx-[20px] sm:ml-[15px] sm:mr-0">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto menu-order px-lg-0 justify-end">
            <Menu data={systemMenu} />
          </Navbar.Collapse>
          <Col className="col-auto text-end pe-0 pl-[15px] font-size-0">
            {/* <SearchBar /> */}
            {/* <HeaderLanguage className="xs:pl-[15px]" /> */}
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
});

export default HeaderLayout;
