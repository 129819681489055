import React, { memo, useRef } from "react";

// Libraries
import { m } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { PropTypes } from "prop-types";

const Carousel = (props) => {
  const swiperRef = useRef(null);
  return (
      <m.div className={`client-slider-wrapper relative${props.className ? ` ${props.className}` : ""}`} {...props.animation}>
        <Swiper
          ref={swiperRef}
          slidesPerView={4}
          spaceBetween={20}
          loop={true}
          className={props.theme}
          modules={[Navigation, Pagination, Autoplay]}
          {...props.carouselOption}
        >
          {
            props.data?.length > 0 && props.data.map((item, i) => {
              return <SwiperSlide key={i}>{props.renderItem(props, item, i)}</SwiperSlide>
            })
          }
        </Swiper>
        {
          props.carouselOption.navigation && (
            <>
              <div onClick={() => swiperRef.current.swiper.slidePrev()} className="swiper-button-prev absolute"></div>
              <div onClick={() => swiperRef.current.swiper.slideNext()} className="swiper-button-next absolute"></div>
            </>
          )
        }
      </m.div>
    
  );
}

Carousel.defaultProps = {
  data: [],
  animationDelay: 0.2,
  renderItem: (props, item, i) => null
}

Carousel.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      img: PropTypes.string,
      target: PropTypes.string,
      link: PropTypes.string
    })
  ),
  renderItem: PropTypes.func,
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  grid: PropTypes.string,
  carousel: PropTypes.bool,
  carouselOption: PropTypes.object,
}

export default memo(Carousel);