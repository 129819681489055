import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence, m } from "framer-motion";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import { resetForm, sendEmail } from "../Functions/Utilities";
import MessageBox from "../Components/MessageBox/MessageBox";
import Buttons from "../Components/Button/Buttons";
import GoogleMap from "../Components/GoogleMap/GoogleMap";

// Data
import InViewPort from "../Components/InViewPort";
import { NotFoundLayout } from "../App";
import LoadingAnimation from "./LoadingAnimation";
import PageLayout from "./PageLayout";
import { Form, Formik } from "formik";
import {
  ContactFormSchema,
  ContactFormStyle03Schema,
} from "../Components/Form/FormSchema";
import { Checkbox, Input, TextArea } from "../Components/Form/Form";
import ServerClient, { apiHost } from "../API/ServerClient";
import axios from "axios";

const Contact = (props) => {
  const [applicationModel, setApplicationModel] = useState({});
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [langName, setLangName] = useState(
    ServerClient.actions.getActiveLang()
  );
  const form = useRef(null);

  useEffect(() => {
    ServerClient.actions.getAppModel((appModel) => {
      setApplicationModel(appModel);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (props.pageKey) {
      ServerClient.actions.getPageData(props.pageKey, (response) => {
        if (response?.success) {
          setPageDataActiveLang(response.data);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.pageKey]);

  const setPageDataActiveLang = (responseData) => {
    if (langName !== "tr" && responseData.otherLangs?.[langName]) {
      setPageData(responseData.otherLangs?.[langName]);
    } else {
      setPageData(responseData);
    }
  };

  const sendDjangoMail = (values, actions, callBack) => {
    // console.log('SD_', values, actions);
    if (file) {
      setFormIsLoading(true);
      ServerClient.actions.fileUpload(file, (response) => {
        if (response.success) {
          values.file = response.data;
          ServerClient.actions.post(
            ServerClient.requests.SendMail,
            values,
            (response) => {
              if (response?.success) {
                callBack?.();
              } else {
                actions.setErrors("Form Gönderilemedi!");
              }
              setFormIsLoading(false);
            }
          );
        } else {
          actions.setFieldError("file", "Dosya Yüklenemedi!");
          setFormIsLoading(false);
        }
      });
    } else {
      setFormIsLoading(true);
      ServerClient.actions.post(
        ServerClient.requests.SendMail,
        values,
        (response) => {
          if (response?.success) {
            callBack?.();
          } else {
            actions.setErrors("Form Gönderilemedi!");
          }
          setFormIsLoading(false);
        }
      );
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  let pageSections = pageData.sections || [];
  let contactFormSection = pageSections.find((s) => s.type === "contactForm");

  if (pageData) {
    return (
      <>
        <PageLayout pageKey={props.pageKey} />
        <InViewPort>
          {/* Section Start */}
          <m.section
            className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
            {...fadeIn}
          >
            <Container>
              <Row className="justify-center">
                <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
                  <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">
                    {contactFormSection.subTitle}
                  </span>
                  <h4 className="font-serif font-semibold text-darkgray">
                    {contactFormSection.title}
                  </h4>
                </Col>
                <Col className="col-xl-10 col-sm-12 col-xs-12">
                  <Formik
                    initialValues={{
                      subject: "",
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                      file: null,
                    }}
                    validationSchema={ContactFormSchema}
                    onSubmit={async (values, actions) => {
                      actions.setSubmitting(true);
                      sendDjangoMail(values, actions, () => {
                        resetForm(actions);
                        setFile(null);
                        actions.setSubmitting(false);
                      });
                    }}
                  >
                    {({ isSubmitting, status }) => (
                      <Form ref={form}>
                        <Row className="row-cols-1 row-cols-md-2">
                          <Col className="mb-16 sm:mb-[25px]">
                            <Input
                              showErrorMsg={false}
                              type="text"
                              name="subject"
                              className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Konu"
                            />
                            <Input
                              showErrorMsg={false}
                              type="text"
                              name="name"
                              className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="İsim Soyisim"
                            />
                            <Input
                              showErrorMsg={false}
                              type="email"
                              name="email"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Email Adresi"
                            />
                            <Input
                              showErrorMsg={false}
                              type="tel"
                              name="phone"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Telefon Numarası"
                            />
                            <Input
                              showErrorMsg={false}
                              type="file"
                              name="file"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              placeholder="Yüklenecek Dosya"
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                            />
                          </Col>
                          <Col className="mb-16 sm:mb-[20px]">
                            <TextArea
                              className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[366px] resize-none"
                              rows="6"
                              name="message"
                              placeholder="Mesaj"
                            ></TextArea>
                          </Col>
                          <Col className="text-left sm:mb-[20px]"></Col>
                          <Col className="text-right sm:text-center">
                            <Buttons
                              type="submit"
                              className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                                formIsLoading ? " loading" : ""
                              }`}
                              themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                              size="md"
                              color="#fff"
                              title="Gönder"
                              isLoading={formIsLoading}
                            />
                          </Col>
                        </Row>
                        <AnimatePresence>
                          {formIsLoading && (
                            <Row>
                              <Col xs={12}>
                                <div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                >
                                  <MessageBox
                                    className="mt-[20px] py-[10px]"
                                    theme="message-box01"
                                    variant="info"
                                    message="Mesajınız gönderiliyor..."
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                          {status && (
                            <Row>
                              <Col xs={12}>
                                <div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                >
                                  <MessageBox
                                    className="mt-[20px] py-[10px]"
                                    theme="message-box01"
                                    variant="success"
                                    message="Mesajınız başarıyla gönderildi!"
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                        </AnimatePresence>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Container>
          </m.section>
          {/* Section End */}
          {/* Section Start */}
          <section>
            <GoogleMap
              className="grayscale h-[600px] p-0 md:h-[450px] xs:h-[300px]"
              location={contactFormSection.mapLink}
            />
          </section>
          {/* Section End */}
        </InViewPort>
      </>
    );
  } else {
    return <NotFoundLayout />;
  }
};

Contact.propTypes = {
  pageKey: PropTypes.any,
};

export default Contact;
