import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "./PageLayout";

function GeneratedPage() {
  const { pageKey } = useParams();
  return <PageLayout pageKey={pageKey} />;
}

export default GeneratedPage;
