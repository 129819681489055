import React, { useState, useEffect, useRef, memo } from "react";

// Libraries
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { m } from "framer-motion";

// Components
import Pagination from "./HelperComponents/Pagination";
import Filter from "../Portfolio/Filter";

// Data
import { blogData, authorData } from "./BlogData";

// Filter the blog data category wise
const blogGridData = blogData.filter((item) => item.blogType === "simple");

const BlogSimple = (props) => {
  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    import("../../Functions/Utilities").then((module) => {
      const grid = module.initializeIsotop(blogWrapper.current);
      grid.on("arrangeComplete", () => setLoading(false));
    });
  }, []);

  const style = {
    "--overlay-color":
      typeof props.overlay === "object"
        ? `linear-gradient(to right top, ${props.overlay.map(
            (item, i) => item
          )})`
        : props.overlay,
  };

  const handleFilterChange = () => {
    blogWrapper.current
      .querySelectorAll("li")
      .forEach((item) => item.childNodes[0]?.classList.add("appear"));
  };

  return (
    <div className="grid-wrapper">
      {/* Filter Start */}
      <Filter
        title={props.title}
        filterData={props.filterData}
        onFilterChange={handleFilterChange}
      />
      {/* Filter End */}

      {/* Grid Start */}
      <ul
        ref={blogWrapper}
        className={`grid-container ${props.grid ? ` ${props.grid}` : ""}${
          loading ? " loading" : ""
        }${props.filter === false ? "" : " mt-28 md:mt-[4.5rem] sm:mt-8"}`}
      >
        <li className="grid-sizer"></li>
        {props.data.map((item, i) => {
          let pageTitleSection = item.sections.find(
            (s) => s.type == "pageTitle"
          );
          
          return (
            <li
              key={i}
              className={`grid-item${
                item.double_col ? " grid-item-double" : ""
              } ${item.category.toLowerCase()}`}
            >
              <m.div
                className="blog-Simple xs:block"
                style={style}
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <div
                  className="blog-post-image xs:h-[250px] w-full"
                  style={{
                    background: pageTitleSection.backgroundImage
                      ? `url('${pageTitleSection.backgroundImage}')`
                      : pageTitleSection.pageHeaderColor || "rgb(242,129,87)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Link
                    aria-label="link"
                    to={`${[item.link]}`}
                  ></Link>
                </div>
                <div className="post-details">
                  {/* <Link
                    aria-label="link"
                    to={`/reference/${[
                      item.category[0]
                        .toString()
                        .split(" ")
                        .join("")
                        .toLowerCase(),
                    ]}`}
                    className="blog-category"
                  >
                    {item.category[0]}
                  </Link> */}
                  <Link
                    aria-label="link"
                    to={`${[item.link]}`}
                    className={`blog-title ${!item.description ? "mb-0" : ""} min-h-[150px]`}
                  >
                    {item.title}
                  </Link>
                  {
                    item.description ? (
                      <p className="mb-[25px] xl:mb-[25px] md:mb-[20px] xs:mb-[15px]">
                        {item.description.length <= 150 ? item.description : item.description.slice(0, 150) + '...'};
                      </p>
                    ) : null
                  }
                  {/* <Link
                    aria-label="link"
                    to={`/blogs/author/${item.author}`}
                    className="blog-author"
                  >
                    {
                      authorData.filter(
                        (author) => author.id === item.author
                      )[0].name
                    }
                  </Link> */}
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
      {/* Grid End */}

      {/* Pagination Start */}
      {/* {props.pagination === true && (
        <div className="flex justify-center mt-[7.5rem] md:mt-20">
          <Pagination />
        </div>
      )} */}
      {/* Pagination End */}
    </div>
  );
};

BlogSimple.defaultProps = {
  filter: false,
  data: [],
  link: "/blog-types/blog-standard-post/",
};

BlogSimple.propTypes = {
  pagination: PropTypes.bool,
  title: PropTypes.string,
  grid: PropTypes.string,
  link: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      category: PropTypes.array,
      tags: PropTypes.array,
      blogType: PropTypes.string,
      img: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      author: PropTypes.number,
      likes: PropTypes.number,
      comments: PropTypes.number,
      date: PropTypes.string,
      double_col: PropTypes.bool,
    })
  ),
};

export default memo(BlogSimple);
