import { useState, useEffect } from "react";

const useViewport = () => {
  const [viewport, setViewport] = useState("lg");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setViewport("sm");
      } else if (window.innerWidth < 768) {
        setViewport("md");
      } else if (window.innerWidth < 1024) {
        setViewport("lg");
      } else if (window.innerWidth < 1280) {
        setViewport("xl");
      } else if (window.innerWidth < 1536) {
        setViewport("2xl");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return viewport;
};

export default useViewport;
