import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

// Components
import {
  SectionHero,
  SectionAbout,
  SectionExperiences,
  SectionTabList,
  SectionAccordion,
  SectionTeam,
  SectionCounter,
  SectionParallax,
  SectionLastPosts,
  SectionPageTitle,
  SectionFancyTextBox,
  SectionMainAbout,
  SectionClient,
  SectionIconWithText,
  SectionPageContent,
} from "./Sections";

// Data
import InViewPort from "../Components/InViewPort";
import ServerClient from "../API/ServerClient";
import { NotFoundLayout } from "../App";
import LoadingAnimation from "./LoadingAnimation";
import PageMetaData from "./PageMetaData";

// Context
import GlobalContext from "../Context/Context";
import { Col, Container, Row } from "react-bootstrap";
import { fadeIn, fadeInLeft, zoomIn } from "../Functions/GlobalAnimations";
import Careousel from "../Components/Carousel/Careousel";
import { Link } from "react-router-dom";
import { m } from "framer-motion";

const About = (props) => {
  const [pageData, setPageData] = useState(null);
  const [referanslar, setReferanslar] = useState(null);
  const [teknolojiveuygulamalar, setTeknolojiveuygulamalar] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [langName, setLangName] = useState(
    ServerClient.actions.getActiveLang()
  );
  const { setLogoInfo } = useContext(GlobalContext);

  useEffect(() => {
    setIsLoading(true);
    if (props.pageKey) {
      ServerClient.actions.getPageData(props.pageKey, (response) => {
        if (response?.success) {
          setPageDataActiveLang(response.data);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.pageKey]);

  const setPageDataActiveLang = (responseData) => {
    if (langName !== "tr" && responseData.otherLangs?.[langName]) {
      setPageData(responseData.otherLangs?.[langName]);
    } else {
      setPageData(responseData);
    }
  };

  useEffect(() => {
    let pageLogoInfo = {};
    if (pageData?.logoColor) {
      pageLogoInfo.color = pageData.logoColor;
    }
    if (pageData?.logoBgColor) {
      pageLogoInfo.bgColor = pageData.logoBgColor;
    }
    setLogoInfo(pageLogoInfo);
    getTeknolojiveUygulamalar();
    getReferanslar();
  }, [pageData]);

  const onSaveSection = (sectionData) => {
    let newPageData = pageData;
    let sectionIndex = newPageData.sections.findIndex(
      (s) => s.id === sectionData.id
    );
    newPageData.sections[sectionIndex] = sectionData;
    setPageData(newPageData);
  };

  const getTeknolojiveUygulamalar = () => {
    ServerClient.actions.get(
      ServerClient.requests.GetCategoryPages,
      {
        category: "teknoloji_uygulama",
      },
      (result) => {
        if (result.data?.length > 0) {
          let list = [];
          result.data.map((page) => {
            if (page.isVisible) {
              let activeLang = ServerClient.actions.getActiveLang();
              if (page.otherLangs?.[activeLang]) {
                return page.otherLangs?.[activeLang];
              }
              if (!list.find((p) => p.uuid == page.uuid)){
                list.push(page);
              }
            }
          });
          setTeknolojiveuygulamalar(list);
        }
      }
    );
  };

  const getReferanslar = () => {
    ServerClient.actions.get(
      ServerClient.requests.GetCategoryPages,
      {
        category: "referanslar",
      },
      (result) => {
        if (result.data?.length > 0) {
          let list = [];
          result.data.map((page) => {
            if (page.isVisible) {
              let activeLang = ServerClient.actions.getActiveLang();
              if (page.otherLangs?.[activeLang]) {
                return page.otherLangs?.[activeLang];
              }
              if (!list.find((p) => p.uuid == page.uuid)){
                list.push(page);
              }
            }
          });
          setReferanslar(list);
        }
      }
    );
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }


  if (pageData) {
    let pageSections = pageData.sections || [];
    pageSections = pageSections.sort(function (x, y) {
      if (x.order < y.order) return -1;
      if (x.order > y.order) return 1;
      return 0;
    });

    return (
      <>
        <PageMetaData pageData={pageData} />
        {/* Lazy Load HTML */}
        <InViewPort>
          {pageSections.map((section, index) => {
            let sectionDiv = null;
            let sectionProps = {
              key: index,
              sectionData: section,
              editMode: props.editMode,
              onSave: onSaveSection,
            };
            // if (section.type === "hero") {
            //   sectionDiv = <SectionHero {...sectionProps} />;
            // }
            // else 
            if (section.type === "pageTitle") {
              sectionDiv = <SectionPageTitle {...sectionProps} />;
            } 
            // else if (section.type === "about") {
            //   sectionDiv = <SectionAbout {...sectionProps} />;
            // } else if (section.type === "experiences") {
            //   sectionDiv = <SectionExperiences {...sectionProps} />;
            // } else if (section.type === "tabList") {
            //   sectionDiv = <SectionTabList {...sectionProps} />;
            // } else if (section.type === "accordion") {
            //   sectionDiv = <SectionAccordion {...sectionProps} />;
            // } else if (section.type === "team") {
            //   sectionDiv = <SectionTeam {...sectionProps} />;
            // } else if (section.type === "counter") {
            //   sectionDiv = <SectionCounter {...sectionProps} />;
            // } else if (section.type === "parallax") {
            //   sectionDiv = <SectionParallax {...sectionProps} />;
            // } else if (section.type === "lastPosts") {
            //   sectionDiv = <SectionLastPosts {...sectionProps} />;
            // } else if (section.type === "fancyTextBox") {
            //   sectionDiv = <SectionFancyTextBox {...sectionProps} />;
            // } 
            else if (section.type === "mainAbout") {
              sectionDiv = <SectionMainAbout {...sectionProps} />;
            } 
            // else if (section.type === "clients") {
            //   sectionDiv = <SectionClient {...sectionProps} />;
            // } else if (section.type === "iconWithText") {
            //   sectionDiv = <SectionIconWithText {...sectionProps} />;
            // }
            return sectionDiv;
          })}
          
          {teknolojiveuygulamalar?.length > 0 && (
            <>
              {/* Section Teknoloji ve Uygulamalar Start */}
              <section className="py-[130px] overflow-hidden consulting-blogclean bg-[#f9f6f3] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container>
                  <Row className="justify-center">
                    <Col
                      xl={6}
                      lg={7}
                      sm={8}
                      className="text-center mb-16 font-serif font-medium"
                    >
                      {/* <span className="text-[#bf8c4c] uppercase inline-block mb-[15px]">{"Teknoloji ve Uygulamalar"}</span> */}
                      <h2 className="heading-5 text-darkgray inline-block tracking-[-.5px]">
                        Teknoloji ve Uygulamalar
                      </h2>
                    </Col>
                  </Row>
                  <Careousel
                    overlay={["#bf8c4c", "#445a58"]}
                    className="swiper-navigation-04 swiper-navigation-light"
                    data={teknolojiveuygulamalar}
                    animation={fadeIn}
                    carousel={teknolojiveuygulamalar?.length >= 3}
                    carouselOption={{
                      slidesPerView: 1,
                      loop: false,
                      spaceBetween: 20,
                      autoplay: teknolojiveuygulamalar?.length >= 3 ? { delay: 5000, disableOnInteraction: true } : false,
                      navigation: teknolojiveuygulamalar?.length >= 3,
                      breakpoints: {
                        1200: { slidesPerView: 4 },
                        992: { slidesPerView: 3 },
                        768: { slidesPerView: 3 },
                      },
                    }}
                    renderItem={(props, item, index) => {
                      const style = {
                        "--overlay-color":
                          typeof props.overlay === "object"
                            ? `linear-gradient(to right top, ${props.overlay.map(
                                (item, i) => item
                              )})`
                            : props.overlay,
                      };
                      let pageTitleSection = item.sections.find(
                        (s) => s.type == "pageTitle"
                      );
                      let fotoDiv = (
                        <div
                          style={{
                            height: "280px",
                            width: "350px",
                            background: pageTitleSection.backgroundImage
                              ? `url('${pageTitleSection.backgroundImage}')`
                              : "rgb(242,129,87)",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      );
                      return (
                        <m.div
                          className="blog-post rounded-[6px] overflow-hidden"
                          style={style}
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.6, ease: "easeOut" }}
                        >
                          <div
                            className="blog-post-img relative overflow-hidden"
                            style={style}
                          >
                            <Link
                              aria-label="link"
                              to={`${[item.link]}`}
                              className=""
                            >
                              {fotoDiv}
                            </Link>
                          </div>
                          <div className="post-details p-[30px] sm:px-[25px] bg-white">
                            {/* <span className="text-md uppercase"> {item.date} </span> */}
                            <Link
                              aria-label="link"
                              to={`${[item.link]}`}
                              className="font-medium text-darkgray font-serif block text-center"
                            >
                              {item.title}
                            </Link>
                          </div>
                        </m.div>
                      );
                    }}
                  />
                </Container>
              </section>
              {/* Section Teknoloji ve Uygulamalar End */}
            </>
          )}

          {referanslar?.length > 0 && (
            <>
              {/* Section Referanslar Start */}
              <section className="py-[130px] overflow-hidden consulting-blogclean bg-[#f9f6f3] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container>
                  <Row className="justify-center">
                    <Col
                      xl={6}
                      lg={7}
                      sm={8}
                      className="text-center mb-16 font-serif font-medium"
                    >
                      {/* <span className="text-[#bf8c4c] uppercase inline-block mb-[15px]">{"Teknoloji ve Uygulamalar"}</span> */}
                      <h2 className="heading-5 text-darkgray inline-block tracking-[-.5px]">
                        Referanslar
                      </h2>
                    </Col>
                  </Row>
                  <Careousel
                    overlay={["#bf8c4c", "#445a58"]}
                    className="swiper-navigation-04 swiper-navigation-light"
                    data={referanslar}
                    animation={fadeIn}
                    carousel={referanslar?.length >= 3}
                    carouselOption={{
                      slidesPerView: 1,
                      loop: false,
                      spaceBetween: 20,
                      autoplay: referanslar?.length >= 3 ? { delay: 5000, disableOnInteraction: true } : false,
                      navigation: referanslar?.length >= 3,
                      breakpoints: {
                        1200: { slidesPerView: 4 },
                        992: { slidesPerView: 3 },
                        768: { slidesPerView: 3 },
                      },
                    }}
                    renderItem={(props, item, index) => {
                      let pageTitleSection = item.sections.find(
                        (s) => s.type == "pageTitle"
                      );
                      let fotoDiv = (
                        <div
                          style={{
                            height: "179px",
                            width: "255px",
                            background: pageTitleSection.backgroundImage
                              ? `url('${pageTitleSection.backgroundImage}')`
                              : pageTitleSection.pageHeaderColor ||
                                "rgb(242,129,87)",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      );

                      return (
                        <m.div
                          className="blog-classic"
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.6, ease: "easeOut" }}
                        >
                          <div className="blog-post-image">
                            <Link
                              aria-label="blog post image"
                              to={`${[item.link]}`}
                            >
                              {fotoDiv}
                            </Link>
                          </div>
                          <div className="post-details">
                            <Link
                              aria-label="blog post title"
                              to={`${[item.link]}`}
                              className="blog-title"
                            >
                              {item.title}
                            </Link>
                            <p>{item.description}</p>
                          </div>
                        </m.div>
                      );
                    }}
                  />
                </Container>
              </section>
              {/* Section Referanslar End */}
            </>
          )}
        </InViewPort>
      </>
    );
  } else {
    return <NotFoundLayout />;
  }
};

About.propTypes = {
  pageKey: PropTypes.any,
};

export default About;
