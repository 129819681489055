import React, { useContext, useRef } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Form, Formik } from "formik";

// Components
import { fadeIn, fadeInLeft, zoomIn } from "../Functions/GlobalAnimations";
import Lists from "../Components/Lists/Lists";
import Accordions from "../Components/Accordion/Accordion";
import BlogClean from "../Components/Blogs/BlogClean";
import TextBox from "../Components/TextBox/TextBox";
import CustomModal from "../Components/CustomModal";
import Buttons from "../Components/Button/Buttons";
import Team from "../Components/Team/Team";
import Counter from "../Components/Counters/Counter";
import Tab07 from "../Components/Tab/Tab07";
import FancyTextBox from "../Components/FancyTextBox/FancyTextBox";
import Clients from "../Components/Clients/Clients";
import IconWithText from "../Components/IconWithText/IconWithText";
import { Checkbox, Input, InputEditor, TextArea } from "../Components/Form/Form";
import GlobalContext from "../Context/Context";
import Logo from "../Components/Logo";
import useViewport from "../Functions/useViewport";

function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

const SectionEditForm = (props) => {
  const { style, sectionData, keys, onSave } = props;
  const form = useRef(null);
  let initialValues = {};
  keys.map((k, i) => {
    initialValues[k.name] = k.value;
  });

  return (
    <>
      {/* Modal Component Start */}
      <CustomModal.Wrapper
        style={style}
        className={`absolute top-[20px] left-[20px] items-start flex h-full w-full justify-start`}
        modalBtn={
          <Buttons
            ariaLabel="modal button"
            type="submit"
            className="btn-sonar border-0 shadow-[0_0_15px_rgba(0,0,0,0.1)]"
            color="#fff"
            themeColor="#bf8c4c"
            size="lg"
            title={<i className="feather-edit text-[35px]" />}
          />
        }
      >
        <div className="w-[1020px] max-w-full relative rounded mx-auto">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              let newValues = {
                ...sectionData,
                ...values,
              };
              onSave(newValues);
            }}
          >
            {({ isSubmitting, status }) => (
              <Form ref={form}>
                <Row className="row-cols-1 row-cols-md-2">
                  <Col className="mb-16 sm:mb-[25px]">
                    {keys.map((k, i) => {
                      if (k.component) {
                        return (
                          <InputEditor
                            key={i}
                            showErrorMsg={false}
                            type={k.type}
                            name={k.name}
                            label={k.label}
                            className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                            labelClass="mb-[25px]"
                          />
                        );
                      } else {
                        return (
                          <Input
                            key={i}
                            showErrorMsg={false}
                            type={k.type}
                            name={k.name}
                            label={k.label}
                            className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                            labelClass="mb-[25px]"
                          />
                        );
                      }
                    })}
                  </Col>
                  <Col className="text-right sm:text-center">
                    <Buttons
                      type="submit"
                      className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`}
                      themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                      size="md"
                      color="#fff"
                      title="Kaydet"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </CustomModal.Wrapper>
      {/* Modal Component End */}
    </>
  );
};

export const SectionHero = (props) => {
  const { sectionData, editMode, onSave } = props;
  let keys = [
    {
      type: "file",
      name: "backgroundImageFile",
      value: "",
      component: "Input",
    },
    {
      type: "text",
      name: "title",
      value: sectionData.title,
      component: "Input",
    },
    {
      type: "text",
      name: "subTitle",
      value: sectionData.subTitle,
      component: "Input",
    },
    {
      type: "text",
      name: "stickyTitle",
      value: sectionData.stickyTitle,
      component: "Input",
    },
    {
      type: "text",
      name: "stickyLink",
      value: sectionData.stickyLink,
      component: "Input",
    },
  ];
  return (
    <>
      {/* Hero Section Start */}
      <section className="cover-background bg-dark">
        {editMode ? <SectionEditForm style={{ top: "150px" }} sectionData={sectionData} keys={keys} onSave={onSave} /> : null}
        <div
          className="absolute h-full w-full opacity-60 top-0 left-0 bg-darkslateblue"
          style={
            sectionData.backgroundImage
              ? {
                  backgroundImage: `url('${sectionData.backgroundImage}')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }
              : {}
          }
        ></div>
        <div className={`absolute h-full w-full opacity-75 top-0 left-0 ${sectionData.pageHeaderColor ? `` : "bg-[#00000080]"}`} style={{backgroundColor: sectionData.pageHeaderColor}}></div>
        <Container className="relative">
          <Row className="items-center h-[100vh] md:h-[800px] sm:h-[500px]">
            <Col xl={5} lg={6} md={7} sm={10} className="font-serif font-medium">
              <h2 className=" text-white leading-[65px] tracking-[-1px] mb-[65px] sm:leading-[50px] sm:mb-[25px]">{sectionData.title}</h2>
              {/* <div className="text-md uppercase tracking-[2px] flex">
                <span className="w-[40px] h-[1px] bg-[#bf8c4c] self-center mr-[25px]"></span>
                <span className="text-[#bf8c4c]">{sectionData.subTitle}</span>
              </div> */}
            </Col>
          </Row>
        </Container>
        <div className="flex flex-row justify-end w-[750px] absolute right-0 bottom-0 z-[1] sm:relative sm:w-full">
          <Row className="items-center justify-end mx-0 w-full">
            <Col xs={9} className="items-center flex bg-[#bf8c4c] h-full py-12 px-20">
              <h3 className="heading-5 font-serif text-white font-light m-0">{sectionData.stickyTitle}</h3>
            </Col>
            <Col xs={3} className="p-0 h-full">
              <Link aria-label="link contact classic" to={sectionData.stickyLink} className="text-center flex items-center justify-center bg-[#f9f6f3] h-full">
                <i className="line-icon-Arrow-OutRight text-[60px] text-[#bf8c4c]"></i>
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      {/* Hero Section End */}
    </>
  );
};

export const SectionPageTitle = (props) => {
  const { sectionData, editMode, onSave } = props;
  const { logoInfo } = useContext(GlobalContext);
  const viewport = useViewport();
  let keys = [
    {
      type: "file",
      name: "backgroundImageFile",
      value: "",
    },
    {
      type: "text",
      name: "title",
      value: sectionData.title,
    },
    {
      type: "text",
      name: "subTitle",
      value: sectionData.subTitle,
    },
  ];

  const getStyle = () => {
    let styles = {};
    if (sectionData.pageHeaderHeight) {
      if (viewport == "2xl" || viewport == "xl" || viewport == "lg") {
        styles = {
          mainDiv: {
            height: sectionData.pageHeaderHeight || "450px"
          },
          contextDiv: {
            alignItems: "end", 
            paddingBottom: "35px"
          }
        }
      }
    }
    return styles;
  }

  return (
    <>
      {/* Parallax Scrolling Start */}
      <m.div className={`md:flex md:items-center overflow-hidden relative md:h-[465px] sm:h-[350px] xs:h-[357px]`} {...fadeIn} style={getStyle()?.mainDiv}>
        {editMode ? <SectionEditForm sectionData={sectionData} keys={keys} onSave={onSave} /> : null}
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute left-0 h-[120vh] top-[60px] lg:h-[64vh] lg:top-[-46px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-[-44px] sm:h-[49vh] xs:top-0 xs:h-[40vh] w-full"
          translateY={[-40, 40]}
          style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
        ></Parallax>
        {/* bg-gradient-to-tr from-[#bf8c4c] via-[#7e5420] to-[#5a3405] */}
        <div className={`absolute h-full w-full opacity-75 top-0 left-0 ${sectionData.pageHeaderColor ? `` : "bg-[#000000] bg-opacity-40"}`} style={{backgroundColor: sectionData.pageHeaderColor}}></div>
        <Container fluid={true} className="h-full relative z-1">
          <Row className={`justify-between h-full w-[85%] mr-auto ml-auto mb-[50px] md:mb-0`} style={getStyle()?.contextDiv}>
            <Col xl={6} lg={7} md={10} className="text-left flex h-[350px] justify-end lg:justify-end md:justify-center sm:justify-center flex-col font-serif">
              <span className="inline-block opacity-60 mb-[5px] text-white">{sectionData.subTitle}</span>
              <h1 className="text-white text-[42px] leading-[49px] sm:text-[30px] sm:leading-10 font-medium mb-0">{sectionData.title}</h1>
            </Col>
            <Col xl={3} lg={4} md={6} className="text-right flex h-[350px] justify-end lg:justify-center md:justify-center sm:justify-center flex-col font-serif items-end md:hidden">
              <Logo
                className="md:hidden"
                color={logoInfo.color || "#FFFFFF"}
                bgColor={logoInfo.bgColor || "transparent"}
                inPageHeader={true}
              />
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Scrolling End */}
    </>
  );
};

export const SectionAbout = (props) => {
  const { sectionData, editMode, onSave } = props;
  let keys = [
    {
      type: "file",
      name: "backgroundImageFile",
      value: "",
    },
    {
      type: "text",
      name: "title",
      value: sectionData.title,
    },
    {
      type: "text",
      name: "description",
      value: sectionData.description,
    },
  ];
  return (
    <>
      {/* About section Start */}
      <section
        className="py-[160px] overflow-hidden cover-background lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        {editMode ? <SectionEditForm sectionData={sectionData} keys={keys} onSave={onSave} /> : null}
        <Container>
          <Row className="items-center justify-center">
            <m.div className="col-lg-6 col-md-10 md:mb-20 consulting-about" {...fadeIn}>
              <h2 className="heading-4 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full">{sectionData.title}</h2>
              <p className="w-[70%] mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]" dangerouslySetInnerHTML={{ __html: sectionData.description }} />
              {/* <Lists theme="list-style-02" data={sectionData.list} /> */}
            </m.div>
            <m.div className="col-lg-5 offset-lg-1 col-md-10" {...fadeIn}>
              <div className="relative">
                <div className="bg-darkslateblue absolute h-full w-full top-0 left-0 opacity-20"></div>
                <img className="w-full" alt="" src={sectionData.movieCoverImage} width="457.5px" height="607.66px" />

                {/* Modal Component Start */}
                <CustomModal.Wrapper
                  className="absolute top-0 items-center flex h-full left-[-50px] md:right-0 md:justify-center md:w-full md:left-0"
                  modalBtn={
                    <Buttons
                      ariaLabel="modal button"
                      type="submit"
                      className="btn-sonar border-0 shadow-[0_0_15px_rgba(0,0,0,0.1)]"
                      themeColor="#fff"
                      color="#bf8c4c"
                      size="xxl"
                      title={<i className="feather-play text-[35px]" />}
                    />
                  }
                >
                  <div className="w-[1020px] max-w-full relative rounded mx-auto">
                    <div className="fit-video">
                      <iframe
                        width="100%"
                        height="100%"
                        className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                        controls
                        src={sectionData.movieLink}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </CustomModal.Wrapper>
                {/* Modal Component End */}
              </div>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* About section End */}
    </>
  );
};

export const SectionExperiences = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Experiences section Start */}
      <section
        className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <m.div className="row justify-center" {...fadeIn}>
            <Col xl={6} lg={7} sm={8} className="text-center font-medium mb-12 font-serif xs:mb-0">
              <span className="text-[#bf8c4c] uppercase block mb-[15px]">{sectionData.subTitle}</span>
              <h2 className="heading-5 text-darkgray inline-block">{sectionData.title}</h2>
            </Col>
          </m.div>
          <TextBox grid="row-cols-1 row-cols-md-3 row-cols-sm-2 justify-center gap-y-10" theme="text-box-style-01" data={sectionData.list} animation={fadeIn} ani />
        </Container>
      </section>
      {/* Experiences section End */}
    </>
  );
};

export const SectionTabList = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Tab Section Start */}
      <section
        className="py-[160px] pt-0 border-t border-lightgray overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Tab07 data={sectionData.list} />
      </section>
      {/* Tab Section End */}
    </>
  );
};

export const SectionAccordion = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section
        className="overflow-hidden bg-[#f9f6f3] md:py-[75px] sm:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <Row className="justify-center">
            <Col xl={5} lg={6} md={10} className="flex flex-col justify-center md:py-0 md:mb-20">
              <h2 className="heading-5 font-serif font-medium text-darkgray tracking-[-1px] mb-[4.5rem] w-[75%] md:mb-16 md:w-full">{sectionData.title}</h2>
              <Accordions theme="accordion-style-03" animation={fadeIn} data={sectionData.list} className="consulting-accordion" />
              <p className="mt-12">{sectionData.subTitle}</p>
            </Col>
            <Col xl={{ offset: 1 }} lg={6} md={10}>
              <m.div className="mr-[-30vw] relative md:mr-0" {...fadeInLeft}>
                <img className="w-full" src={sectionData.image} alt="contatc" width={944} height={805} />
              </m.div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionTeam = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <m.section
        className="bg-gradient-to-b from-[#fff] via-[#fdfdfd] to-[#f7f7f7] overflow-hidden pt-[130px] lg:pt-[90px] md:pt-[75px] sm:pt-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col lg={7} sm={8} className="text-center font-serif mb-24 md:mb-12">
              <span className="block mb-[15px]">
                <i className="line-icon-Business-ManWoman text-[50px] opacity-[.45]"></i>
              </span>
              <div className="text-[100px] leading-[95px] text-gradient bg-gradient-to-r from-[#bf8c4c] to-[#445a58] font-bold uppercase tracking-[-7px] xl:tracking-[-4px] lg:text-[90px] md:text-[70px] md:leading-[65px] sm:text-[45px] sm:leading-[43px]">
                {sectionData.title}
              </div>
              <span className="inline-block text-darkgray text-lg uppercase font-medium tracking-[1px] mb-[20px] mt-[10px] md:text-xmd">{sectionData.subTitle}</span>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-[8%] lg:px-[15px]">
          <Team
            themeColor="dark"
            overlay={["#bf8c4ce6", "#445a58"]}
            theme="team-style-03"
            data={sectionData.list}
            grid="row-cols-1 row-cols-lg-4 row-cols-sm-2 gap-y-10"
            animation={fadeIn}
            animationDelay={0.3}
            carousel={false}
          />
        </Container>
      </m.section>
      {/* Section End */}
    </>
  );
};

export const SectionCounter = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px]" style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}>
        <Container>
          <Counter
            theme="counter-style-02"
            grid="row row-cols-1 row-cols-md-4 row-cols-sm-2 items-center justify-center text-center gap-y-10"
            className="heading-4 text-black font-serif"
            as="h2"
            data={sectionData.list}
            duration={2}
          />
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionParallax = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <div className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] md:flex md:items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] xs:bg-[center_center]"
          translateY={[-40, 40]}
          style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
        ></Parallax>
        <div className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
        <Container className="relative">
          <Row className="justify-center">
            <Col xl={7} lg={8} sm={10} className="text-center text-white font-serif">
              {/* Modal Component Start */}
              <CustomModal.Wrapper
                modalBtn={
                  <m.div className="flex items-center justify-center" {...{ ...zoomIn, transition: { duration: 0.6 } }}>
                    <Buttons
                      ariaLabel="modal button"
                      type="submit"
                      className="btn-sonar border-0 mb-[3.5rem]"
                      themeColor="#fff"
                      color="#bf8c4c"
                      size="lg"
                      title={<i className="icon-control-play" />}
                    />
                  </m.div>
                }
              >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    <iframe
                      width="100%"
                      height="100%"
                      className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                      controls
                      src={sectionData.movieLink}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper>
              {/* Modal Component End */}
              <m.div {...fadeIn}>
                <h2 className="heading-4 font-semibold mb-[45px] sm:mb-[25px]">{sectionData.title}</h2>
                <span className="uppercase tracking-[2px]">{sectionData.subTitle}</span>
              </m.div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Section End */}
    </>
  );
};

export const SectionLastPosts = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section
        className="py-[130px] overflow-hidden consulting-blogclean bg-[#f9f6f3] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <Row className="justify-center">
            <Col xl={6} lg={7} sm={8} className="text-center mb-16 font-serif font-medium">
              <span className="text-[#bf8c4c] uppercase inline-block mb-[15px]">{sectionData.subTitle}</span>
              <h2 className="heading-5 text-darkgray inline-block tracking-[-.5px]">{sectionData.title}</h2>
            </Col>
          </Row>
          <BlogClean
            overlay={["#bf8c4c", "#445a58"]}
            filter={false}
            pagination="false"
            grid="grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
            data={sectionData.list}
          />
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionFancyTextBox = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
        {...fadeIn}
      >
        <Container>
          <Row className="sm:justify-center">
            <Col xs={12} sm={8} md={12}>
              <FancyTextBox
                grid="justify-center row-cols-1 row-cols-md-2 row-cols-lg-3 md:gap-y-[30px] xs:gap-y-[15px]"
                theme="fancy-text-box-02"
                data={sectionData.list}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}
    </>
  );
};

export const SectionMainAbout = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section
        id="about"
        className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <Row className="font-serif" style={{minHeight: "525px"}}>
            <Col lg={8} md={6} className="pe-lg-0 flex items-center sm:mb-[30px]">
              <div className="justify-center h-full w-full flex flex-col items-start bg-[#bf8c4c] px-[5.5rem] lg:px-[3rem] md:p-16">
                <span className="text-xlg lg:text-lg lg:leading-[26px] font-medium text-white mb-[20px] block" dangerouslySetInnerHTML={{ __html: sectionData.list[0].title }}/>
                <p className="text-white font-sans opacity-70 mb-[20px] xs:mb-[15px]" dangerouslySetInnerHTML={{ __html: sectionData.list[0].description }}/>
              </div>
            </Col>
            {/* <Col lg={4} className="ps-lg-0 flex md:mb-[30px]">
              <div className="w-full md:h-[700px] sm:h-[550px] xs:h-[450px] cover-background" style={{ backgroundImage: `url('${sectionData.list[0].image}')` }}></div>
            </Col> */}
            <Col lg={4} md={6} className="flex flex-col">
              <div className="bg-white px-[3.5rem] py-[3rem] h-full lg:p-8 sm:p-16">
                <span className="text-darkgray font-medium mb-[10px] block" dangerouslySetInnerHTML={{ __html: sectionData.list[1].title }} />
                <p className="font-sans" dangerouslySetInnerHTML={{ __html: sectionData.list[1].description }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionClient = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section
        className="py-[50px] lg:py-[90px] md:py-[75px] xs:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <Row className="items-center justify-center">
            <Col xl={12} lg={12} md={12} sm={12} className="text-left sm:text-center sm:mb-[30px]">
              <h5 className="font-serif text-darkgray font-medium mb-0">{sectionData.title}</h5>
            </Col>
            <Col className="mt-[3rem] sm:mt-20">
              <Clients grid="row-cols-2 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 gap-y-10 gap-x-10" theme="client-logo-style-01" data={sectionData.list} animation={fadeIn} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionIconWithText = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section
        className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] xs:py-[50px]"
        style={sectionData.backgroundImage ? { backgroundImage: `url('${sectionData.backgroundImage}')` } : {}}
      >
        <Container>
          <Row className="justify-center flex">
            <m.div className="text-center mb-20 md:mb-12 col-lg-6 col-sm-8" {...fadeIn}>
              <span className="text-xmd mb-[15px] font-serif block w-full">{sectionData.subTitle}</span>
              <h5 className="font-serif text-darkgray font-medium mb-8 sm:w-full">{sectionData.title}</h5>
            </m.div>
            <Col xs={12} md={9} lg={12}>
              <IconWithText
                grid="row-cols-1 row-cols-md-1 row-cols-lg-2 gap-y-[15px]"
                theme="icon-with-text-02 about-us-icon-with-text"
                data={sectionData.list}
                animation={fadeIn}
                animationDelay={0.1}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

export const SectionPageContent = (props) => {
  const { sectionData } = props;
  return (
    <>
      {/* Section Start */}
      <section className="generated-context py-[100px] overflow-hidden cover-background lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <m.div className="col-lg-12 col-md-12 md:mb-20 consulting-about" {...fadeIn}>
              <p className="" dangerouslySetInnerHTML={{ __html: sectionData }} />
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};
