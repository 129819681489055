import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

// Components
import {
  SectionHero,
  SectionAbout,
  SectionExperiences,
  SectionTabList,
  SectionAccordion,
  SectionTeam,
  SectionCounter,
  SectionParallax,
  SectionLastPosts,
  SectionPageTitle,
  SectionFancyTextBox,
  SectionMainAbout,
  SectionClient,
  SectionIconWithText,
  SectionPageContent,
} from "./Sections";

// Data
import InViewPort from "../Components/InViewPort";
import ServerClient from "../API/ServerClient";
import { NotFoundLayout } from "../App";
import LoadingAnimation from "./LoadingAnimation";
import PageMetaData from "./PageMetaData";

// Context
import GlobalContext from "../Context/Context";
import { Col, Container, Row } from "react-bootstrap";
import BlogClean from "../Components/Blogs/BlogClean";
import Clients from "../Components/Clients/Clients";
import { fadeIn, fadeInLeft, zoomIn } from "../Functions/GlobalAnimations";
import Careousel from "../Components/Carousel/Careousel";
import { Link, useParams } from "react-router-dom";
import { m } from "framer-motion";
import BlogReferans from "../Components/Blogs/BlogReferans";

const clientRef = [
  {
    img: "/media/refs/GYH Metro.png",
    text: "Gayrettepe-Yeni Havalimanı Metro",
    type: "metro",
  },
  {
    img: "/media/refs/HYH Metro.png",
    text: "Halkalı Yeni Havalimanı Metro",
    type: "metro",
  },
  {
    img: "/media/refs/Özgün İmşaat Tuzla Metro.png",
    text: "Kaynarca-Pendik-Tuzla Metro (Özgün İnşaat)",
    type: "metro",
  },
  {
    img: "/media/refs/Doğuş Logo.svg",
    text: "Çekmeköy-Sancaktepe Metro (Doğuş)",
    type: "metro",
  },
  {
    img: "/media/refs/Aga Enerji Ataköy İkitelli Metro.png",
    text: "Ataköy-İkitelli Metro (Aga Enerji)",
    type: "metro",
  },
  {
    img: "/media/refs/Bayburt Grup Başakşehir-Kayaşehir Metro.png",
    text: "Başakşehir Kayaşehir Metro (Bayburt Grup)",
    type: "metro",
  },
  {
    img: "/media/refs/canakkale-koprusu.png",
    text: "Çanakkale Köprüsü",
    type: "karayolu_demiryolu",
  },
  {
    img: "/media/refs/avrasya-tuneli-logo-new.png",
    text: "Avrasya Tüneli",
    type: "karayolu_demiryolu",
  },
  {
    img: "/media/refs/TCDD.png",
    text: "TCDD",
    type: "karayolu_demiryolu",
  },
  {
    img: "/media/refs/Marmaray_logo.svg.png",
    text: "Marmaray",
    type: "karayolu_demiryolu",
  },
  {
    img: "/media/refs/İçtaş İnşaat.jpg",
    text: "Yavuz Sultan Selim Köprüsü (İçtaş İnşaat)",
    type: "karayolu_demiryolu",
  },
  {
    img: "/media/refs/benesta-benleo-acibadem-logo.svg",
    text: "Benesta Benleo Acıbadem",
    type: "otel_avm_konut",
  },
  {
    img: "/media/refs/yapiyapilogo.jpeg",
    text: "Yapı Yapı",
    type: "otel_avm_konut",
  },
  {
    img: "/media/refs/Emaar.svg",
    text: "Emaar",
    type: "otel_avm_konut",
  },
  {
    img: "/media/refs/guryapilogo.png",
    text: "Gür Yapı",
    type: "otel_avm_konut",
  },
  {
    img: "/media/refs/galataportlogo.png",
    text: "Galataport",
    type: "otel_avm_konut",
  },
  {
    img: "/media/refs/Akçansa.svg",
    text: "Akçansa",
    type: "endustri",
  },
  {
    img: "/media/refs/Sisecam_yatay_logo.png",
    text: "Eskişehir Şişecam",
    type: "endustri",
  },
  {
    img: "/media/refs/İsdemir_Logo.jpg",
    text: "İskenderun Demir Çelik",
    type: "endustri",
  },
  {
    img: "/media/refs/gurislogo.png",
    text: "Güriş Rüzgar Türbini",
    type: "endustri",
  },
  {
    img: "/media/refs/ozka-insaat.jpg",
    text: "Özka İski Ataşehir Arıtma Tesis",
    type: "endustri",
  },
];

const categories = {
  metro: {
    key: "metro",
    link: "metro-projeleri",
    title: "Metro Projeleri",
    description: "description",
    keywords: "keywords",
  },
  karayolu_demiryolu: {
    key: "karayolu_demiryolu",
    link: "karayolu-ve-demiryolu-projeleri",
    title: "Karayolu ve Demiryolu Projeleri",
    description: "description",
    keywords: "keywords",
  },
  baraj: {
    key: "baraj",
    link: "baraj-projeleri",
    title: "Barajlar",
    description: "description",
    keywords: "keywords",
  },
  otel_avm_konut: {
    key: "otel_avm_konut",
    link: "otel-avm-ve-konut-projeleri",
    title: "Otel, AVM ve Konut Projeleri",
    description: "description",
    keywords: "keywords",
  },
  endustri: {
    key: "endustri",
    link: "endustriyel-tesis-projeleri",
    title: "Endüstriyel Tesisler",
    description: "description",
    keywords: "keywords",
  },
};

const CategoryPages = (props) => {
  const [pages, setPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [langName, setLangName] = useState(
    ServerClient.actions.getActiveLang()
  );
  const { categoryLink } = useParams();

  const category = Object.values(categories).find(
    (c) => c.link == categoryLink
  );

  useEffect(() => {
    getPages();
  }, []);

  const getPages = () => {
    setIsLoading(true);
    if (category?.key) {
      ServerClient.actions.get(
        ServerClient.requests.GetCategoryPages,
        {
          category: category.key,
        },
        (result) => {
          if (result.data?.length > 0) {
            let list = [];
            result.data.map((page) => {
              if (page.isVisible) {
                if (page.otherLangs?.[langName]) {
                  return page.otherLangs?.[langName];
                }
                list.push(page);
              }
            });
            setPages(list);
          }
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (!category) {
    return <NotFoundLayout />;
  }

  let clients = clientRef.filter((rp) => rp.type == category.key);

  return (
    <>
      <PageMetaData
        pageData={{
          title: category.title,
          description: category.description,
          keywords: category.keywords,
        }}
      />
      {/* Lazy Load HTML */}
      <InViewPort>
        <SectionPageTitle
          sectionData={{
            type: "pageTitle",
            title: category.title,
            pageHeaderColor: "#3F76A6",
          }}
        />
        {clients.length > 0 && (
          <SectionClient
            sectionData={{
              type: "clients",
              title: "Referanslarımız",
              list: clientRef.filter((rp) => rp.type == category.key),
            }}
          />
        )}
        {pages?.length > 0 ? (
          <>
            {/* Section Referanslar Start */}
            <section className="py-[130px] overflow-hidden consulting-blogclean bg-[#f9f6f3] lg:py-[90px] md:py-[75px] sm:py-[50px]">
              <Container>
                <BlogReferans
                  overlay="#374162"
                  data={pages}
                  grid={
                    pages?.length > 1
                      ? "grid grid-2col xl-grid-2col lg-grid-2col md-grid-1col sm-grid-1col xs-grid-1col gutter-double-extra-large"
                      : "grid grid-1col xl-grid-1col lg-grid-1col md-grid-1col sm-grid-1col xs-grid-1col gutter-double-extra-large"
                  }
                  //   pagination={true}
                />
              </Container>
            </section>
            {/* Section Referanslar End */}
          </>
        ) : (
          <section className="py-[130px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
            <Container>
              <Row className="items-center justify-center">
                <Col md={12} className="text-left sm:text-center sm:mb-[30px]">
                  <p className="font-serif text-darkgray font-medium mb-0">
                    Projelerimizi incelemeniz için özenle hazırlıyoruz.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </InViewPort>
    </>
  );
};

export default CategoryPages;
