import React, { memo, useEffect, useRef } from "react";

// Libraries
import { useField } from "formik";

export const Input = memo(({ label, labelClass, className, showErrorMsg, getRef, ...props }) => {
  const [field, meta] = useField(props);
  const inputRef = useRef();

  useEffect(() => {
    if (getRef) {
      getRef(inputRef);
    }
  }, [inputRef]);

  return (
    <label className={`block relative ${meta.touched && meta.error ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
      {label}
      <input ref={inputRef} className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} />
      {meta.touched && meta.error && showErrorMsg ? <span className="text-sm text-error block mt-[5px]">{meta.error}</span> : null}
    </label>
  );
});

export const TextArea = memo(({ label, labelClass, className, showErrorMsg, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <label className={`block relative${meta.touched && meta.error ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
      {label}
      <textarea className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} />
      {meta.touched && meta.error && showErrorMsg ? <span className="text-sm text-error block mt-[5px]">{meta.error}</span> : null}
    </label>
  );
});

export const Checkbox = memo(({ label, labelClass, className, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <label className={`block relative${meta.touched && meta.error ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
      <input type="checkbox" className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} />
      {children}
    </label>
  );
});

export const InputEditor = memo(({ label, labelClass, className, showErrorMsg, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <label className={`block relative ${meta.touched && meta.error ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
      {label}
      {/* <input className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} /> */}
      {/* <CKEditor
        editor={ClassicEditor}
        data="<p>Hello from CKEditor&nbsp;5!</p>"
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log("Editor is ready to use!", editor);
        // }}
        onChange={(event, editor) => {
          let data = editor.getData();
          console.log(data);
        }}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
      /> */}
      {meta.touched && meta.error && showErrorMsg ? <span className="text-sm text-error block mt-[5px]">{meta.error}</span> : null}
    </label>
  );
});

Input.defaultProps = {
  showErrorMsg: true,
};
